// colors
$azure: #0babff;
$blue-main: #0389ff;
$azure-three: #0089ff;
$blue: #26a5ff;
$deep-sky-blue: #0080ff;
$clear-blue: #2681ff;
$onahau: #cde7ff;
$leafy-green: #40b837;
$grey-blue: #8194aa;
$azure-10: #e6f4ff;
$grey-main: #333940;
$blue-grey: #5f7389;
$blue-grey-two: #7f93a9;
$blue-grey-three: #98a5b3;
$blue-grey-four: #94a5b8;
$ice-blue: #f4f8f9;
$ice-blue-two: #e6f4ff;
$light-blue-grey: #d5dce6;
$cloudy-blue: #b8c2cc;
$cloudy-blue-two: #c4d4df;
$light-blue-grey-two: #d8e1e8;
$pale-grey: #ebeff2;
$pale-grey-two: #dadfe6;
$pale-grey-three: #f7fafc;
$pale-grey-five: #dfe5eb;
$steel-light: #9cacbe;
$steel-two: #718296;
$navy: #001e40;
$steel: #78899c;
$slate: #536880;
$slate-two: #596c80;
$pale-sky-blue: #dae1e6;
$charcoal-grey: #323c47;
$navy-two: #01264f;
$navy-three: #051f3e;
$dark-indigo: #0f2e4d;
$dark-indigo-three: #0a2540;
$dark-indigo-four: #061729;
$orange: #cf7c1d;
$orange-main: #ff6227;
$red: #ff3d55;
$vermillion: #ff1010;
$natural-white: #ffffff;

// gradients
$blue-gradient: linear-gradient(to right, #0080ff 0%, #0daeff 51%, #0080ff 100%);
$blue-simple-gradient: linear-gradient(to left, #0daeff, #0080ff);
$red-gradient: linear-gradient(to right, #f63048 0%, #ff5737 51%, #f63048 100%);
$red-simple-gradient: linear-gradient(to left, #ff5737, #f63048);

// z-index
$zindex-tooltip: 1070;

// sizes
$sizes: (1x: 8px, 2x: 16px, 3x: 24px, 4x: 32px, 5x: 40px, 6x: 48px, 7x: 56px, 8x: 64px, 9x: 72px, 10x: 80px, 11x: 88px, 12x: 96px);
@function size($size) {
  @return map-get($sizes, $size);
}

// Adaptive
$desktop-menu-breakpoint: 1100px;
