html {
  box-sizing: border-box;
}

body {
  min-width: 320px;
  margin: 0;
  color: $grey-main;
  font-family: 'Figtree', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 22px;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input, textarea, select, button {
  font-weight: 500;
  outline: none;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1,h2,h3,h4,h5,h6,p,ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

li {
  list-style-type: none;
}

hr.divider {
  display: block;
  height: 1px;
  width: 100%;
  border: none;
  box-shadow: none;
  background: $pale-grey;
}

.pointer {
  cursor: pointer;
}

@keyframes sk-load {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}
