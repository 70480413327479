// .table
//   .table__header
//     .table__row
//       .table__col Date
//       .table__col Zip
//   .table__body
//     .table__row
//       .table__col 2018-10-08
//       .table__col 91002

.table {
  position: relative;
  display: block;
  overflow-x: auto;

  &__header {
    .table__row {
      min-height: 40px;

      &:hover {
        background-color: #fff;
        cursor: default;
      }
    }

    .table__col {
      text-transform: uppercase;
      font-size: 11px;
      letter-spacing: 1px;
      line-height: 14px;
      color: $grey-blue;
      font-weight: 500;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 50px;
    padding: 5px 0;
    box-shadow: inset 0 -1px 0 0 $pale-grey;
    transition: background-color 0.2s;

    &:hover {
      background-color: $pale-grey;
    }
  }

  &__col {
    flex: 1;
    padding: 0 5px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    color: $grey-main;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.zebra {
  &:nth-child(2n+1) {
    background-color: $pale-grey-three;
  }
}
