// bootstrap issue https: //github.com/twbs/bootstrap/issues/4160
.pac-container {
  z-index: 1051 !important;
}

.bs-datepicker {
  $this: &;
  box-shadow: 0 5px 15px 0 rgba(2, 36, 71, 0.2), 0 3px 10px 0 rgba(2, 36, 71, 0.1);

  &.date-range {
    box-shadow: unset;

    #{$this}-head,
    #{$this}-body {
      min-width: 255px;
    }

    button {
      &.current {
        padding: 0;
      }
    }
  }

  &-head {
    background-color: unset;
    padding: 10px 0;

    button {
      color: $grey-main;

      &:disabled {
        color: $blue-grey-three;
        background-color: unset;
        cursor: not-allowed;

        &:hover {
          color: $blue-grey-three;
          background-color: unset;
        }
      }

      &:hover {
        background-color: unset;
      }

      &.previous,
      &.next {
        position: relative;
        top: -2px;
      }
    }
  }

  &-body {
    border: none;
    min-height: unset;

    table {
      &.days {
        span {
          width: unset;
        }
      }

      &.months td {
        span {
          border-radius: unset;
        }

        &.is-highlighted {
          span {
            color: $grey-main;
            box-shadow: -1px -1px 0 0 #e4e7e7, inset -1px -1px 0 0 #e4e7e7;
          }
        }

        &.disabled {
          span {
            cursor: not-allowed;
          }
        }
      }

      thead {
        tr {
          display: flex;
          justify-content: space-between;

          th {
            flex: 1;
          }
        }
      }

      tbody {
        display: block;
        margin-top: 8px;

        tr {
          display: flex;
          justify-content: space-between;

          td {
            flex: 1;

            span {
              border-radius: unset;
              color: $grey-main;
              font-weight: 500;
              box-shadow: -1px -1px 0 0 #e4e7e7, inset -1px -1px 0 0 #e4e7e7;

              &::before {
                left: 0;
                right: 0;
              }

              &[class*="select-"] {
                border-radius: unset;
              }

              &.disabled {
                color: $blue-grey-three;
                cursor: not-allowed;
              }

              &.is-highlighted {
                border-radius: unset;

                &.in-range {
                  background-color: #cceeff !important;
                }

                &.selected {
                  background-color: $blue-main !important;
                }
              }

              &.in-range {
                background-color: #cceeff;
                box-shadow: -1px -1px 0 0 #afe1fa, inset -1px -1px 0 0 #afe1fa;
                border-radius: unset;
              }

              &.selected {
                z-index: 1;
                font-weight: bold;
                color: #fff;
                background-color: $blue-main;
                box-shadow: -1px -1px 0 0 $blue-main, inset -1px -1px 0 0 $blue-main;
              }

              &.is-other-month {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
