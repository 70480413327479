.toast-container {
  display: flex;
  flex-direction: column;
}

.notify {
  display: block !important;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6px;
  overflow: hidden;
  padding: 10px 30px;
  border-radius: 5px;
  box-shadow: 0 0 12px #999999;
  background-image: none;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: $pale-grey-two;
  &.toast-success {
    background-color: rgba($navy, 0.8);
  }

  &.toast-warning {
    background-color: rgba(255, 16, 16, 0.8);
  }
}
