a {
  font-size: 14px;
  color: $blue-main;
  text-decoration: none;
  transition: color 0.2s ease;

  &:hover,
  &:active {
    color: #00a4ff;
    text-decoration: underline;
    cursor: pointer;
  }

  &.btn {
    &:hover {
      text-decoration: none;
    }
  }
}

.pseudo-link {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: $blue-main;
  transition: color 0.2s ease;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    background-color: $blue-main;
    transition: opacity 0.2s ease;
    opacity: 0;
  }

  &:hover,
  &:active {
    color: $blue;
    cursor: pointer;

    &:before {
      opacity: 1;
    }
  }
}

.pseudo-btn {
  display: inline-block;
  color: $blue-grey;
  cursor: pointer;

  &:hover {
    color: $blue-main;
  }
}
