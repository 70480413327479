$check-icon-default: '/assets/common/icons/check-icon-default.svg';
$check-icon-active: '/assets/common/icons/check-icon-active.svg';

/*
  .checkbox
    input.checkbox__input(type="checkbox" )
    label.checkbox__label Checkbox label
*/

.checkbox {
  &__input {
    position: absolute;
    display: none;
    z-index: -1;

    &:checked + label {
      color: $grey-main;
      font-weight: 500;

      &::before {
        background-image: url($check-icon-active);
      }
    }

    &:disabled + label {
      cursor: not-allowed;

      &::before {
        background-image: url($check-icon-default);
      }
    }
  }

  &__label {
    position: relative;
    margin: 0;
    padding: size(2x) 23px size(2x) 28px;
    font-size: 14px;
    font-weight: 500;
    color: $blue-grey;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 19px;
      height: 19px;
      margin: auto;
      background-image: url($check-icon-default);
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}
