.table-wrap {
  margin: 20px auto 50px;
  padding-top: 0;

  .ngx-datatable.material {
    box-shadow: none;
    border: 1px solid $pale-grey;
    border-radius: 5px;
    overflow-x: auto;

    .datatable {
      &-header {
        height: 40px;

        &-cell {
          padding: 14px 16px;
          font-size: 11px;
          line-height: 11px;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: $grey-blue;

          &.align-right {
            text-align: right;
          }
        }
      }

      &-body {
        min-height: 10px !important;
        overflow-x: inherit;

        .progress-linear {
          height: 10px;

          .container {
            max-width: 100%;
          }
        }

        &-row {
          box-shadow: inset 0 -1px 0 0 $pale-grey;

          &.datatable-row-even {
            background-color: $pale-grey-three;
          }

          &.datatable-row-odd {
            background-color: #fff;
          }

          .datatable-body-cell {
            padding: 16px;
            margin: 0;
            overflow-x: inherit;
            font-size: 14px;
            font-weight: 500;

            &-label {
              position: relative;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              font-family: Figtree;
              font-size: 14px;
              line-height: 1.43;
            }
          }
        }
      }

      &-footer {
        border: none;

        .page-count {
          display: none;
        }

        .datatable-pager {
          text-align: left;

          .pager {
            li:first-child,
            li:last-child {
              display: none;
            }

            .pages,
            li:nth-child(2),
            li:nth-last-child(2) {
              width: 30px;
              height: 30px;
              margin-right: 10px;
              border-radius: 5px;
              background-color: #fff;
              border: solid 1px $pale-grey;

              &.active:not(.disabled),
              &:hover:not(.disabled) {
                border: solid 1px $blue-main;

                a {
                  background: none;
                  color: $blue-main;
                }
              }

              a {
                height: 100%;
                width: 100%;
                margin: 0;
                padding-top: 3px;
                font-family: Figtree;
                font-size: 14px;
                font-weight: 300;
                line-height: 1.43;
                text-align: center;
                color: $slate;

                i::before {
                  margin-left: -5px;
                }
              }
            }
          }
        }
      }
    }

    .empty-row {
      height: auto;

      .empty-data-table {
        padding: 60px;
        text-align: center;

        &__title {
          margin: 0;
          padding-top: 10px;
          font-size: 18px;
          color: $grey-blue;
          line-height: 1.89;
        }
      }
    }
  }
}
