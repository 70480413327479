@import "~assets/scss/_variables";
@import "~assets/scss/mixins/all";

/*
.filter
  .filter-container
    .filter-main-controls
      .filter-label Filter by:
      <-- filters -->
    .filter-additional-controls
      <-- controls -->
*/

.filter {
  width: 100%;
  margin: size(2x) auto;

  &-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 0 60px;
  }

  &-main-controls {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px 0;
  }

  &-label {
    position: absolute;
    left: 0;
    line-height: 28px;
    font-size: 14px;
    font-weight: 500;
    color: $slate;
  }

  &-item {
    margin: 0 0 0 10px;

    .dp-filter-btn {
      margin-right: 0;
    }
  }
}
