.container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  padding-right: size(2x);
  padding-left: size(2x);
  margin-right: auto;
  margin-left: auto;

  @include maxW(1200px) {
    max-width: 100%;
  }

  @include maxW(425px) {
    padding-right: size(1x);
    padding-left: size(1x);
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -#{size(1x)};
  margin-left: -#{size(1x)};
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  & > .col,
  & > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.col {
  position: relative;
  flex-basis: 0;
  flex-grow: 1;
  width: 100%;
  min-width: 0;
  max-width: 100%;
  padding-right: size(1x);
  padding-left: size(1x);
}

@for $i from 1 through 11 {
  .col-#{$i} {
    flex: 0 0 percentage(calc($i / 12));
  }
}
