// Globals
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/dropdown";
@import "~ng2-slim-loading-bar-observables/style.css";

// Utilities
@import "~bootstrap/scss/utilities/flex";
@import "~bootstrap/scss/utilities/display";
@import "~bootstrap/scss/utilities/text";

// Assets
@import "./assets/scss/mixins/all";
@import "./assets/scss/variables";
@import "./assets/scss/controls/control";
@import "./assets/scss/controls/global-error";
@import "./assets/scss/controls/checkbox";
@import "./assets/scss/controls/radio-button";
@import "./assets/scss/controls/slide-toggle";
@import "./assets/scss/controls/form-control-radio";
@import "./assets/scss/controls/dropdown";
@import "./assets/scss/tables/table";
@import "./assets/scss/tables/data-tables";
@import "./assets/scss/base";
@import "./assets/scss/grid";
@import "./assets/scss/inline-svg/inline-svg";
@import "./assets/scss/alerts";
@import "./assets/scss/a";
@import "./assets/scss/sizing";
@import "./assets/scss/spacing";
@import "./assets/scss/typo";
@import "./assets/scss/modal/all";
@import "./assets/scss/tooltip";
@import "./assets/scss/toast";
@import "./assets/scss/filters";
@import "./assets/scss/display";
@import "./assets/scss/visibillity";
@import "./assets/scss/scroll";
@import "./assets/scss/empty-message";
@import "./assets/scss/bootstrap-overrrides";
