.tooltip {
  position: absolute;
  display: block;
  z-index: $zindex-tooltip;
  margin-bottom: 3px;

    &-inner {
    padding: 4px 8px 6px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
    background-color: rgba($navy, 0.8);
    color: $pale-grey-two;
  }

  .tooltip-inner {
    max-width: 480px !important;
    white-space: normal;
  }
}

.tooltip > div {
  animation-name: delayedFadeIn;
  animation-duration: 0.4s;
}

@keyframes delayedFadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
