$sides: (16, 40, 56, 80, 120);

.inline-svg {
  svg {
    width: 24px;
    height: 24px;
    fill: $steel-light;
  }
}

@each $side in $sides {
  .inline-svg--#{$side} {
    svg {
      width: #{$side}px;
      height: #{$side}px;
    }
  }
}
