$points: '480px', '768px', '1024px', '1200px';

.no-display {
  display: none;
}

@each $point in $points {
  .no-display-from-#{str-slice($point, 0, -3)} {
    @include minW($point) {
      display: none !important;
    }
  }
}

@each $point in $points {
  .no-display-to-#{str-slice($point, 0, -3)} {
    @include maxW($point) {
      display: none !important;
    }
  }
}

.block {
  display: block;
}

@each $point in $points {
  .block-from-#{str-slice($point, 0, -3)} {
    @include minW($point) {
      display: block;
    }
  }
}

@each $point in $points {
  .block-to-#{str-slice($point, 0, -3)} {
    @include maxW($point) {
      display: block;
    }
  }
}

.flex {
  display: flex;
  flex-wrap: wrap;

  &-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.flex-1 {
  flex: 1;
}

@each $point in $points {
  .flex-from-#{str-slice($point, 0, -3)} {
    @include minW($point) {
      display: flex;
    }
  }
}

@each $point in $points {
  .flex-to-#{str-slice($point, 0, -3)} {
    @include maxW($point) {
      display: flex;
    }
  }
}

@for $i from 1 through 5 {
  .order-#{$i} {
    order: #{$i} !important;
  }
}
