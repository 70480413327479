/*
.f-15-400 {
  font-size: 15px;
  font-weight: 400;
}

.f-c-grey-main {
  color: $grey-main;
}
*/

$font_size: (10, 11, 12, 13, 14, 16, 18, 20, 24, 26, 32, 34);
$font_weight: (normal, 400, 500, 600, 700, bold);
$font_color:
  'azure' $azure,
  'blue-grey' $blue-grey,
  'blue-grey-three' $blue-grey-three,
  'blue-grey-two' $blue-grey-two,
  'charcoal-grey' $charcoal-grey,
  'cloudy-blue' $cloudy-blue,
  'grey-main' $grey-main,
  'grey-blue' $grey-blue,
  'light-blue-grey' $light-blue-grey,
  'slate' $slate,
  'steel-light' $steel-light,
  'steel' $steel,
  'red' $red,
  'pale-grey' $pale-grey;

@each $size in $font_size {
  @each $weight in $font_weight {
    .f-#{$size}-#{$weight} {
      font-size: #{$size}px !important;
      font-weight: #{$weight} !important;
    }
  }
}

@each $classname, $color in $font_color {
  .f-c-#{$classname} {
    color: $color !important;
  }
}

.text-overflow {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nowrap {
  white-space: nowrap;
}

.uppercase {
  text-transform: uppercase;
}
