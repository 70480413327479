.alert {
  position: relative;

  &-dismissible {
    .close {
      position: absolute;
      top: 50%;
      right: 5px;
      z-index: 999;
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: inherit;
      font-size: 0;
      cursor: pointer;
      background-color: transparent;
      background-image: none;
      background-position: center;
      background-repeat: no-repeat;
      border: none;
      transform: translateY(-13px);

      @include minW(768px) {
        right: 20px;
      }
    }
  }

  &-success {
    color: $grey-main;
    background-color: #a3d96c;
  }

  &-info {
    color: $grey-main;
    background-color: #cce7ff;
  }

  &-primary {
    color: $grey-main;
    background-color: #fff;
  }

  &-warning {
    color: $grey-main;
    background-color: #ffc266;
  }

  &-danger {
    color: $grey-main;
    background-color: #ff8080;
  }
}
