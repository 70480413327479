$radio-icon-default: '/assets/common/icons/radio-icon-default.svg';
$radio-icon-active: '/assets/common/icons/radio-icon-active.svg';
$radio-icon-hover: '/assets/common/icons/radio-icon-default.svg';

/*
  .radio
    input.radio-button__input(type="radio", id="some_id", name="some_name")
    label.radio-button__label(for="some_id") Label
*/

.radio-button {
  &__input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:checked + label {
      &::before {
        background-image: url($radio-icon-active);
      }
    }
  }

  &__label {
    position: relative;
    margin: 0;
    padding: 0 23px 0 28px;
    font-size: 14px;
    color: $blue-grey;
    cursor: pointer;

    &:hover {
      &::before {
        background-image: url($radio-icon-hover);
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 22px;
      height: 22px;
      margin: auto;
      background-image: url($radio-icon-default);
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}
