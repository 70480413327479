.modal {
  &.sidebar-modal {
    justify-content: flex-end;
    align-items: flex-start;
    height: 100vh;
    padding: 0;
  }

  &.fade-anim {
    opacity: 0;
    transition: opacity 200ms ease-in-out;

    &.in {
      opacity: 1;

      .sidebar {
        animation: modal-fade-in-right 0.3s;
      }
    }

    .sidebar {
      animation: modal-fade-out-right 0.3s;
    }
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    width: 530px;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    padding: size(3x);
    background-color: #fff;

    @include maxW(530px) {
      width: 100vw;
    }

    .header {
      position: relative;
      padding-bottom: size(3x);

      &__icon {
        margin-right: size(2x);

        svg {
          fill: $blue-main;
        }
      }

      &__title {
        font-size: size(3x);
        line-height: 1.5;
        color: $grey-main;
      }

      .close {
        position: absolute;
        top: 6px;
        right: 0;
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .body {
      flex: 1;
      margin-right: -14px;
      padding-right: 14px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 3px;
        background-color: $pale-grey;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: $steel-light;
      }
    }

    .footer {
      margin-top: auto;
    }
  }
}
