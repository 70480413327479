@mixin maxW($maxw) {
  @media (max-width: $maxw) {
    @content;
  }
}

@mixin maxH($maxh) {
  @media (max-height: $maxh) {
    @content;
  }
}

@mixin minW($minw) {
  @media (min-width: $minw) {
    @content;
  }
}

@mixin minH($minh) {
  @media (min-height: $minh) {
    @content;
  }
}
