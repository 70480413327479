@import "~assets/scss/variables";

.backdrop {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
}

.menu-nav {
  width: 240px;
  background: $natural-white;
  height: 100vh;

  .menu-header {
    background-color: $navy;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: size(2x) size(1x) size(2x) size(2x);


    .close-button {
      width: 24px;
      height: 24px;
      position: relative;
      cursor: pointer;
    }

    .close-button::before,
    .close-button::after {
      content: '';
      position: absolute;
      width: 24px;
      height: 2px;
      background-color: $steel-light;
      top: 50%;
      left: 0;
    }

    .close-button::before {
      transform: rotate(45deg);
    }

    .close-button::after {
      transform: rotate(-45deg);
    }
  }

  .menu-content {
    padding-top: size(3x);
    overflow: hidden auto;
    height: calc(100vh - 56px - 24px);

    & .nav-link {
      width: 100%;
      display: flex;
      color: $slate;
      font: 600 16px/24px 'Figtree', sans-serif;
      padding: size(2x);
      text-decoration: none;

      &:hover {
        background-color: $ice-blue-two;
      }
    }

    & .active-nav-link img {
      filter: invert(45%) sepia(100%) saturate(7462%) hue-rotate(196deg) brightness(96%) contrast(102%);
    }

    & .active-child-link {
      color: $grey-main;
    }

    & hr {
      width: 192px;
      border-color: rgba(156, 172, 190, 0.15);
      margin: 2px auto;
    }

    .chevron,
    .expanded {
      transition: transform 150ms ease-in;
    }

    .expanded {
      transform: rotate(180deg);
      transition: 150ms ease-out;
    }
  }
}
