.modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: $zindex-modal;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  padding: 20px 0;
  background-color: rgba(0, 30, 64, 0.6);
  outline: 0;

  @include minW(900px) {
    margin-right: calc(-1 * (100vw - 100%));
  }

  @include maxW(560px) {
    padding: 20px size(2x);
  }

  &-open {
    overflow: hidden;
  }

  .modal-content {
    position: relative;
    width: 100%;
    pointer-events: auto;
    background-color: $pale-grey;
    background-clip: padding-box;
    border-radius: size(1x);
    outline: 0;
    width: 560px;

    @include maxW(560px) {
      width: calc(100vw - size(4x));
    }

    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      width: size(5x);
      height: size(5x);
      color: #fff;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }

    .modal-header {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 80px;
      padding: 0 size(8x);
      background-color: #fff;
      border-radius: 10px 10px 0 0;

      @include maxW(560px) {
        padding: 0 size(2x);
      }

      &::before {
        content: "";
        position: absolute;
        bottom: -11px;
        left: 0;
        right: 0;
        width: 30px;
        height: 30px;
        margin: auto;
        background-color: #fff;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        border-radius: 6px;
        z-index: 2;
      }

      .modal-title {
        font-size: size(3x);
        font-weight: 500;
        color: $grey-main;
      }
    }

    .modal-body {
      text-align: center;
      padding: size(5x) size(8x) size(4x);

      @include maxW(560px) {
        padding: size(5x) size(2x) size(4x);
      }

      &__icon {
        margin-bottom: size(3x);
      }

      &__title {
        margin-bottom: size(1x);
        font-weight: 600;
      }

      &__text {
        line-height: 1.5;
        color: $grey-main;
      }
    }

    .modal-footer {
      padding: 0 56px 56px;
    }
  }

  &.fade-anim {
    opacity: 0;
    transition: opacity 200ms ease-in-out;

    &.in {
      opacity: 1;

      .modal-content {
        animation: modal-fade-in-top 0.3s;
      }
    }
  }
}
