
// label.slide-toggle
//   input.slide-toggle__control(type="checkbox")
//   .slide-toggle__handle
//   .slide-toggle__bg

.slide-toggle {
  position: relative;
  flex: 0 0 35px;
  width: 35px;
  height: 20px;
  margin-bottom: 0;
  border-radius: 15px;
  background-color: $light-blue-grey;
  font-weight: 600;
  cursor: pointer;

  &__handle {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #fff;
    transition: all 0.2s linear;
    z-index: 2;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    border-radius: 15px;
    opacity: 1;
    background-color: transparent;
    transition: all 0.2s linear;
    z-index: 1;
  }

  &__control {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    visibility: hidden;

    &:checked ~ .slide-toggle__handle {
      left: 100%;
      margin-left: -18px;
    }

    &:checked ~ .slide-toggle__bg {
      background-color: $leafy-green;
    }

    &:disabled ~ .slide-toggle__handle {
      cursor: not-allowed;
    }

    &:disabled ~ .slide-toggle__bg {
      background: #fff;
      opacity: 0.4;
    }

    &:disabled:checked ~ .slide-toggle__bg {
      background-color: $leafy-green;
    }
  }
}
